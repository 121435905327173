import moment from 'moment-timezone'
export default function authHeader () {
  const user: '{}' = JSON.parse(localStorage.getItem('user') || '{}')
  const lang: '{}' = JSON.parse(localStorage.getItem('lang') || '{}')
  let timezone = moment.tz.guess()
  if (timezone === 'Europe/Kiev') {
    timezone = 'Europe/Kyiv'
  }
  localStorage.setItem('timezone', timezone)
  if (user) {
    return { Authorization: 'Bearer ' + user, 'Content-Language': lang, 'X-User-Timezone': timezone }
  } else {
    return { 'Content-Language': lang, 'X-User-Timezone': timezone }
  }
}
