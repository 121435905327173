<template>
  <div
    v-if="message"
    class="alert alert-danger"
    role="alert"
  >
    <div
      v-for="item in message"
      :key="item.id"
    >
      {{ item }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AlertBlock',
  props: {
    message: {
      type: [String, Boolean, Array],
      default: false
    }
  }
})
</script>

<style lang="scss" scoped>
.alert {
  position: absolute;
  left: 0;
  // bottom: -20px;
  color: #ef5350;
  font-size: 12px;
  line-height: 14px;
  border-top: 1px solid #ef5350;
  width: 100%;
  text-align: left;
  padding-top: 3px;
  z-index: 1;
  max-height: 30px;
  overflow: hidden;
}
</style>
